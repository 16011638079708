<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">订单中心</a>
          <i>></i>
          <a href="javascript:;" @click="goBack()">企业内训订单</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">详情</a>
        </span>
      </div>
      <div class="framePage-body framePage-tabs">
        <div style="display: flex">
          <p>订单编号:{{orderNo}}</p>
        <p style="margin-left:15px;">企业信息:{{compName}}</p>
        </div>
        
        <el-tabs
        class="tabsFirst"
          v-model="activeName"
          :before-leave="beforeTabLeave"
          @tab-click="tabChange"
        >
          <el-tab-pane label="课程内容" name="first">
           <distributeCourseList ref="distributeCourseList" :btnShow="true"  :orderId='orderId'/>
          </el-tab-pane>
          <el-tab-pane label="试卷内容" name="second">
           <distributeTestPaper ref="distributeTestPaper" :btnShow="true" :orderId='orderId'/>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import distributeCourseList from "@/views/CustomPrograms/distributeCourseList.vue"; //课程内容
import distributeTestPaper from "@/views/CustomPrograms/distributeTestPaper.vue"; //试卷内容
export default {
  name: "distributeContent",
  components: {
    distributeCourseList,
    distributeTestPaper
  },
  mixins: [List],
  data() {
    return {
      activeName: "first", //tab页默认值 
      orderId:this.$route.query.orderId, //订单Id
      orderNo:this.$route.query.orderNo, //订单编号
      compName:this.$route.query.compName, //订单编号
    };
  },
  mounted() {},
  computed: {},
  methods: {
    //tab切换
    tabChange(tab) {
      this.activeName = tab.name;
    },
    //返回
    goBack() {
        this.$router.push({
          path: "/web/CustomPrograms/enterpriseInternalTrainingOrder",
          query: {
            refresh: true,
          },
        });
      
    },
  },
};
</script>
<style lang="less" scoped>
.framePage-tabs {
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  padding: 1rem 0.675rem;
  box-sizing: border-box;
   .tabsFirst {
    width: 100%;
  }
}

</style>
